import { createSlice } from '@reduxjs/toolkit'

export const articleInfoSlice = createSlice({
    /*
    This function creates a new reducer (takes an action and the previous state of the application and returns the new state)
    for React-Redux library (acts as a global variable)

    Parameters
    ----------
    None

    Returns
    -------
    None
    */
    name: 'articleInfo',
    initialState: {
        globalArticleInfo: null,
    },
    reducers: {
        setGlobalArticleInfo: (state, action) => {
            if (state.globalArticleInfo !== action.payload) {
                state.globalArticleInfo = action.payload;
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { setGlobalArticleInfo } = articleInfoSlice.actions

export default articleInfoSlice.reducer