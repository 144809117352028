import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";

import "firebase/compat/auth";
import "firebase/compat/firestore";

export default function Footer() {
  /*
  This is a component function in JSX that contains the HTML markup that represent each graphical element on the webpage

  Parameters
  ----------
  None

  Returns
  -------
  DOM File
      A HTML markup that contains graphical elements
  */

  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [buttonClickCount, setButtonClickCount] = useState(0);
  const [tokensLeft, setTokensLeft] = useState(0);

  useEffect(() => {
    const unsubscribeAuth = firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
      setIsLoggedIn(!!user);
    });

    return () => unsubscribeAuth();
  }, []);

  useEffect(() => {
    let unsubscribeFirestore;

    if (user) {
      const docRef = firebase.firestore().collection('customers-web').doc(user.uid);
      unsubscribeFirestore = docRef.onSnapshot((doc) => {
        const data = doc.data();
        if (data) {
          // If the document exists and has a button_click_count field, use that value
          setButtonClickCount(data.button_click_count || 0);
        } else {
          // If the document does not exist or does not have a button_click_count field, use 0
          setButtonClickCount(0);
        }
      });
    }

    return () => {
      if (unsubscribeFirestore) {
        unsubscribeFirestore();
      }
    }
  }, [user]);

  useEffect(() => {
    let count = 3 - buttonClickCount;
    if (count < 0) {
      count = 0;
    }
    setTokensLeft(count);
  }, [buttonClickCount]);

  return (
    <div className="p-3 opacity-75 text-gray-900 text-center bg-white fixed bottom-0 rounded-3xl" style={{ left: "50%", transform: "translate(-50%, -50%)" }}>
      <div className="scale-90 sm:scale-100">
        {isLoggedIn ? (
          <span className="text-lg md:text-xl">
            You have {tokensLeft} token{tokensLeft === 0 || tokensLeft === 1 ? "" : "s"} left.
          </span>
        ) : (
          <span className="text-lg md:text-xl">
            Hey, what's up?
          </span>
        )}
      </div>
    </div>
  );
}
